import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"

export default function ContactTp() {

      
const [isOpened, setOpen] = useState("false");
  
  const contactToggle = () => {
    setOpen(!isOpened); 
  };

  const data = useStaticQuery(graphql`
    {
      allWpGcontact {
        edges {
          node {
            contactPage {
              contactPageLayout {
                ... on WpGcontact_Contactpage_ContactPageLayout_ContactElements {
                  animationCompleteText
                  aosSettings
                  contactBlockId
                  contactColumnEmail
                  contactColumnText
                  iconLinks {
                    iconSvg
                    iconUrl
                    socialLinkTitle
                  }
                  pageHeading
                  preAnimationText
                }
              }
            }
          }
        }
      }
    }
    `)
    return (
        <section id="contact-block" className="contact-block" data-aos="contact-block" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="false" data-aos-anchor-placement="top-center" data-aos-mirror="false">
              

          <div className="three-column-page">
          <div className={`content-block slide-right ${!isOpened ? "opened" : "closed"}`}>
              <div className="center-wrap">
                  <div className="wrap">
                      <h1>{data.allWpGcontact.edges[0].node.contactPage.contactPageLayout[0].pageHeading}</h1>
                  </div>
              </div>
          </div>
              
          <div className="content-block">
            <div className="center-wrap">
                <button id="contact-animation"  className={`${!isOpened ? "opened" : "closed"}`}  onClick={contactToggle}  aria-label="Contact menu">
                    <div>
                      <span data-vistext="Hide details">{data.allWpGcontact.edges[0].node.contactPage.contactPageLayout[0].preAnimationText}</span>
          
                      </div>
                </button>
            </div>
          </div>
            
          <div className={`content-block slide-left ${!isOpened ? "opened" : "closed"}`}>
              <div className="center-wrap">
                  <div className="wrap">
                      <p>{data.allWpGcontact.edges[0].node.contactPage.contactPageLayout[0].contactColumnText}</p>
                      <a href={`mailto:${data.allWpGcontact.edges[0].node.contactPage.contactPageLayout[0].contactColumnEmail}`}>{data.allWpGcontact.edges[0].node.contactPage.contactPageLayout[0].contactColumnEmail}</a>
                      <div className="icon-line">
                      {data.allWpGcontact.edges[0].node.contactPage.contactPageLayout[0].iconLinks.map(node => (
                          <a href={node.iconUrl} target="_blank" key={node.iconUrl} rel="noreferrer noopener" title={node.socialLinkTitle} dangerouslySetInnerHTML={{__html: node.iconSvg}}>                                    
                          </a>
                      ))} 
                      </div>
                  </div>

              </div>
          </div>
          </div>
    
              

        </section>
    )



}

